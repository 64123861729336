/* eslint-disable no-unused-vars */

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-video-banner"]'
  );

  if (elements.length) {
    const { default: VideoBanner } = await import(
      /* webpackChunkName: "o-video-banner" */ './video-banner'
    );
  }
}
/* eslint-enable no-unused-vars */
