export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="m-content-video"]'
  );
  if (elements.length) {
    const { default: ContentVideo } = await import(
      /* webpackChunkName: "m-content-video" */ './content-video'
    );

    elements.forEach((element) => new ContentVideo(element));
  }
}
