export default function init() {
  const lazyImages = document.querySelectorAll('img.lazyload');
  const image = document.createElement('img');
  const srcSetSupport = 'srcset' in image && 'sizes' in image;

  const initLazyload = async () => {
    await import(/* webpackChunkName: "a-image--lazysizes" */ 'lazysizes');
  };

  if (lazyImages.length) initLazyload();

  const loadPicturefill = async () => {
    await import(/* webpackChunkName: "a-image-picturefill" */ 'picturefill');
  };

  if (!srcSetSupport) loadPicturefill();
}
