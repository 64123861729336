export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-ticket-stage"]'
  );
  if (elements.length) {
    const { default: TicketStage } = await import(
      /* webpackChunkName: "o-ticket-stage" */ './ticket-stage'
    );

    elements.forEach((element) => new TicketStage(element));
  }
}
