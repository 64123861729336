/* eslint-disable no-unused-vars */

export default async function init() {
  const element = document.querySelector('[data-js-module="o-anchor-links"]');

  if (element) {
    const { default: AnchorLinks } = await import(
      /* webpackChunkName: "o-anchor-links" */ './anchor-links'
    );
    new AnchorLinks(element);
  }
}
/* eslint-enable no-unused-vars */
