import CookiesService from './cookiesService';

const storage = () => {
  try {
    return window.sessionStorage;
  } catch {
    return null;
  }
};

const setItem = (key, value) => {
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (e) {
    CookiesService.setCookie(key, value, { expires: 0 });
  }
};

const removeItem = (key) => {
  try {
    storage.removeItem(key);
  } catch (e) {
    CookiesService.setCookie(key, '', { expires: -1 });
  }
};

const getValue = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const getItem = (key) => {
  try {
    const item = storage.getItem(key);
    return getValue(item);
  } catch (e) {
    return CookiesService.getCookie(key);
  }
};

export default {
  setItem,
  removeItem,
  getItem
};
