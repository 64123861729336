export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="m-event-teaser"]'
  );
  if (elements.length) {
    const { default: EventTeaser } = await import(
      /* webpackChunkName: "m-event-teaser" */ './event-teaser'
    );

    elements.forEach((element) => new EventTeaser(element));
  }
}
