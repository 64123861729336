import Footer from './footer';

/**
 * Initialize the footer text positioning
 *
 * We do not use the async code loading as:
 *
 * 1) The footer is definitively on every page
 * 2) The async loading boilerplate is larger than this code
 *
 * @returns {void}
 */
const init = () => {
  const $footer = document.querySelector('[data-js-module="o-footer"]');
  if ($footer) {
    new Footer($footer);
  }

  const $el = document.querySelector('.o-footer__social-inner');
  const $wave = document.querySelector('.o-footer__social');
  if (!$el || !$wave) {
    return;
  }

  const obs = new ResizeObserver(() => {
    const { x, width } = $el.getBoundingClientRect();
    const left = x + width / 2;

    $wave.style.setProperty('--wave-offset-x', `${left}px`);
  });
  obs.observe($wave);
};

export default init;
