export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-teaser-tabs"]'
  );
  if (elements.length) {
    const { default: TeaserTabs } = await import(
      /* webpackChunkName: "o-teaser-tabs" */ './teaser-tabs'
    );

    elements.forEach((element) => new TeaserTabs(element));
  }
}
