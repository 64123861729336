export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-stage-upright"]'
  );
  if (elements.length) {
    const { default: StageUpright } = await import(
      /* webpackChunkName: "o-stage-upright" */ './stage-upright'
    );

    elements.forEach((element) => new StageUpright(element));
  }
}
