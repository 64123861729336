export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="m-tag-list"]');
  if (elements.length) {
    const { default: TagList } = await import(
      /* webpackChunkName: "m-tag-list" */ './tag-list'
    );

    elements.forEach((element) => new TagList(element));
  }
}
