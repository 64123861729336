export default async function init() {
  const socialWall = document.querySelector('[data-js-module="m-social-wall"]');
  await import(
    /* webpackChunkName: "postmessage-polyfill" */ '../../../../javascripts/vendor/postmessage'
  );

  let iframe;

  if (socialWall) {
    iframe = socialWall.querySelectorAll(
      '[data-js-element="m-social-wall__iframe"]'
    );

    if (iframe) {
      [iframe] = iframe;
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          intersectionObserver.unobserve(socialWall);

          // lazy load frame
          iframe.src = iframe.dataset.src;

          // subscribe to postmessage
          window.pm.bind('resize', (data) => {
            iframe.setAttribute('height', `${data.height}px`);
          });
        }
      });
    }, {});

    intersectionObserver.observe(socialWall);
  }
}
