export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-share-button"]'
  );

  if (elements.length) {
    const { default: ShareButton } = await import(
      /* webpackChunkName: "o-share-button" */ './share-button.js'
    );

    elements.forEach((element) => new ShareButton(element));
  }
}
