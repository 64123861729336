export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="m-gallery-slider"]'
  );
  if (elements.length) {
    const { default: GallerySlider } = await import(
      /* webpackChunkName: "m-gallery-slider" */ './gallery-slider'
    );

    elements.forEach((element) => new GallerySlider(element));
  }
}
