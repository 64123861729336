import triggerCustom from '../../../../javascripts/events/triggerCustom';
import BaseModule from '../../../../javascripts/helpers/baseModule';

export default class Footer extends BaseModule {
  constructor(element) {
    super(element, element.getAttribute('data-js-module'));

    this.getElements([
      {
        id: 'installButton',
        selector: 'pwa-button'
      }
    ]);

    if (this.elements.installButton) {
      this.elements.installButton.addEventListener('click', () => {
        triggerCustom(window, 'PWAHandler.prompt');
      });
    }
  }
}
