export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-stage-street-gigs"]'
  );
  if (elements.length) {
    const { default: StageStreetGigs } = await import(
      /* webpackChunkName: "o-stage-street-gigs" */ './stage-street-gigs'
    );

    elements.forEach((element) => new StageStreetGigs(element));
  }
}
