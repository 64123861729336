export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="m-livestream-teaser"]'
  );
  if (elements.length) {
    const { default: LivestreamTeaser } = await import(
      /* webpackChunkName: "m-livestream-teaser" */ './livestream-teaser.jsx'
    );

    elements.forEach((element) => new LivestreamTeaser(element));
  }
}
