import cookieService from './cookiesService';

const authState = {
  logInState: null,
  requestRunning: false
};

const LOGIN_COOKIE_KEY = 'streetgigs-login';

const BATCH_INTERVAL = 100;

const waitForResponse = () =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!authState.requestRunning) {
        resolve(authState.logInState);
        clearInterval(interval);
      }
    }, BATCH_INTERVAL);
  });

/**
 * @returns {Promise<boolean>} - resolves the check in a boolean
 */
const requestCheckLoggedIn = () => {
  authState.requestRunning = true;
  return new Promise((resolve) => {
    const loginState = !!cookieService.getCookie(LOGIN_COOKIE_KEY);
    resolve(loginState);
    authState.requestRunning = false;
  });
};

const checkLoggedIn = () =>
  authState.requestRunning ? waitForResponse() : requestCheckLoggedIn();

const isLoggedIn = async () => {
  if (authState.logInState === null) {
    const response = await checkLoggedIn();
    authState.logInState = response;
  }
  return authState.logInState;
};

export default {
  isLoggedIn
};
