export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="a-input"]');
  if (elements.length) {
    const { default: Input } = await import(
      /* webpackChunkName: "a-input" */ './input'
    );

    elements.forEach((element) => new Input(element));
  }
}
