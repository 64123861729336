export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-teaser-grid"]'
  );
  if (elements.length) {
    const { default: TeaserGrid } = await import(
      /* webpackChunkName: "o-teaser-grid" */ './teaser-grid.jsx'
    );

    elements.forEach((element) => new TeaserGrid(element));
  }
}
