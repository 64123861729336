export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="m-mixed-copy"]');
  if (elements.length) {
    const { default: MixedCopy } = await import(
      /* webpackChunkName: "m-mixed-copy" */ './mixed-copy'
    );

    elements.forEach((element) => new MixedCopy(element));
  }
}
