const edgeVersion = window.navigator.userAgent.match(/Edge\/(\d{2})\./);
const edgePartialSupport = edgeVersion ? parseInt(edgeVersion[1], 10) >= 16 : false;

const loadShim = () => {
  import(/* webpackChunkName: "shims-object-fit" */ './object-fit-shim');
};

// If the browser does support object-fit, we don't need to continue
if ('objectFit' in document.documentElement.style === false) loadShim();
if (edgeVersion && !edgePartialSupport) loadShim();
