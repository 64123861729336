export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-video-player"]'
  );
  if (elements.length) {
    const { default: VideoPlayer } = await import(
      /* webpackChunkName: "o-video-player" */ './video-player.jsx'
    );

    elements.forEach((element) => new VideoPlayer(element));
  }
}
