export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-landing-stage"]'
  );

  if (elements.length) {
    const { default: LandingStage } = await import(
      /* webpackChunkName: "o-landing-stage" */ './landing-stage.jsx'
    );

    elements.forEach((element) => new LandingStage(element));
  }
}
