export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="m-teaser"]');
  if (elements.length) {
    const { default: Teaser } = await import(
      /* webpackChunkName: "m-teaser" */ './teaser'
    );

    elements.forEach((element) => new Teaser(element));
  }
}
