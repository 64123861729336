export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="a-countdown"]');

  if (elements.length) {
    const { default: Countdown } = await import(
      /* webpackChunkName: "a-countdown" */ './countdown'
    );

    elements.forEach((element) => new Countdown(element));
  }
}
