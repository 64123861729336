/**
 * Initialize custom GetFeedback / Usabilla button
 * @returns {Promise} A Promise that resolves when the buttons have loaded
 */
const init = async () => {
  const elements = document.querySelectorAll(
    '[data-js-module="m-feedback-btn"]'
  );
  if (elements.length) {
    const { default: FeedbackBtn } = await import(
      /* webpackChunkName: "m-feedback-btn" */ './feedback-btn'
    );
    elements.forEach((element) => new FeedbackBtn(element));
  }
};

export default init;
