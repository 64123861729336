export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="m-login-account"]'
  );
  if (elements.length) {
    const { default: LoginAccount } = await import(
      /* webpackChunkName: "m-login-account" */ './login-account'
    );

    elements.forEach((element) => new LoginAccount(element));
  }
}
