import BaseModule from '../../../../javascripts/helpers/baseModule';
import countDownEvents from '../../../../javascripts/events/countDownEvents';

const STATUS_LANE_SELECTOR = '[data-js-module="m-teaser-statuslane"]';

export default class IntroTeaser extends BaseModule {
  constructor(element, selector) {
    super(element, selector);

    this.getElements([
      {
        id: 'teaser',
        selector: 'teaser'
      },
      {
        id: 'tickets',
        selector: 'tickets'
      }
    ]);

    this.initListener();
  }

  initListener() {
    const { teaser, tickets } = this.elements;
    const statusLane = teaser.querySelector(STATUS_LANE_SELECTOR);

    if (statusLane) {
      statusLane.addEventListener(countDownEvents.expiresAtExpired, () =>
        tickets.classList.add('o-ticket-list--hidden')
      );
    }
  }
}
