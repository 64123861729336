/* eslint-disable no-unused-vars */

export default async function init() {
  const element = document.querySelector(
    '[data-js-element="o-external-link-handler"]'
  );

  if (element) {
    const { default: ExternalLinkHandler } = await import(
      /* webpackChunkName: "o-external-link-handler" */ './external-link-handler'
    );
    ExternalLinkHandler(element);
  }
}
/* eslint-enable no-unused-vars */
