/**
 * @file Street Gigs User Data HTML injector
 *
 * The following attributes can be set anywhere in the HTML to inject
 * user data of a logged in street gigs user account:
 *
 *  - data-sg-user-name="textContent" injects the username as .textContent
 *  - data-sg-user-mail="textContent" injects the e-mail address as .textContent
 *  - data-sg-user-name="value" injects the username as .value
 *  - data-sg-user-mail="value" injects the e-mail address as .value
 */
import authService from '../../../../javascripts/helpers/authService';

/**
 * Street Gigs User Data API properties that can be injected into the DOM
 */
const allowedApiProperties = ['name', 'mail'];

/**
 * DOM properties into which API properties can be injected
 */
const allowedDomProperties = ['textContent', 'value'];

/**
 * Get current user data
 *
 * @returns {Promise<Object|boolean>} A promise that resolves with the user data or false
 */
export const getUserData = async () => {
  // Save us an API request if no auth cookie is set
  const isLoggedIn = await authService.isLoggedIn();
  if (!isLoggedIn) {
    return false;
  }

  const res = await fetch('/streetgigs/account/user-data');
  return res.json();
};

/**
 * Initialize street gigs user data injection
 *
 * @returns {Promise<void>} Promise that resolves when properties are injected
 */
const initAtomSgUser = async () => {
  const query = allowedApiProperties
    .map((p) => `[data-sg-user-${p}]`)
    .join(',');

  const $sgUserElements = document.querySelectorAll(query);

  if ($sgUserElements.length === 0) {
    return;
  }

  const userData = await getUserData();
  if (!userData) {
    // Do nothing when not logged in
    return;
  }

  for (let i = 0; i < $sgUserElements.length; i += 1) {
    const $elem = $sgUserElements[i];
    for (let j = 0; j < allowedApiProperties.length; j += 1) {
      const apiProperty = allowedApiProperties[j];

      const attr = $elem.getAttribute(`data-sg-user-${apiProperty}`);
      if (allowedDomProperties.includes(attr) && userData[apiProperty]) {
        $elem[attr] = userData[apiProperty];
      }
    }
  }
};

export default initAtomSgUser;
