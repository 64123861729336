export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="m-teaser-statuslane"]'
  );
  if (elements.length) {
    const { default: TeaserStatuslane } = await import(
      /* webpackChunkName: "m-teaser-statuslane" */ './teaser-statuslane'
    );

    elements.forEach((element) => new TeaserStatuslane(element));
  }
}
