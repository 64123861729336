export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="o-form"]');
  if (elements.length) {
    const { default: FormModule } = await import(
      /* webpackChunkName: "o-form" */ './form'
    );

    elements.forEach((element) => new FormModule(element));
  }
}
