export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-ticket-list"]'
  );
  if (elements.length) {
    const { default: TicketList } = await import(
      /* webpackChunkName: "o-ticket-list" */ './ticket-list'
    );

    elements.forEach((element) => new TicketList(element));
  }
}
