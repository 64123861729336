const instances = new WeakMap();

export default function whatever() {
  const sliderStages = document.querySelectorAll(
    '[data-js-element="o-stage__slideshow"]'
  );

  const init = async () => {
    const { tns } = await import(
      /* webpackChunkName: "tiny-slider" */ 'tiny-slider'
    );
    // Readme https://github.com/ganlanyuan/tiny-slider

    sliderStages.forEach((element) => {
      if (instances.has(element)) {
        return;
      }

      const instance = tns({
        container: element,
        gutter: 18,
        arrowKeys: true,
        mouseDrag: true,
        loop: false,
        nav: true,
        controls: true,
        controlsContainer: '.o-stage__slideshow-controls',
        speed: 800,
        onInit: () => element.classList.add('initialized')
      });

      instances.set(element, instance);
    });
  };

  if (sliderStages.length) {
    init();
  }
}
