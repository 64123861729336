import MobilePrompt from './mobile-prompt';

export default function init() {
  const mobilePrompt = document.querySelector(
    `[data-js-module="o-mobile-prompt"]`
  );
  if (mobilePrompt) {
    new MobilePrompt(mobilePrompt);
  }
}
