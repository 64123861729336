export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="m-account-actions"]'
  );
  if (elements.length) {
    const { default: AccountActions } = await import(
      /* webpackChunkName: "m-account-actions" */ './account-actions'
    );

    elements.forEach((element) => new AccountActions(element));
  }
}
