export default async function init() {
  const header = document.querySelector('[data-js-module="o-header"]');
  if (header) {
    const { default: Header } = await import(
      /* webpackChunkName: "o-header" */ './header'
    );

    new Header(header);
  }
}
