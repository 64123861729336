export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="o-modal"]');
  if (elements.length) {
    const { default: Modal } = await import(
      /* webpackChunkName: "o-modal" */ './modal'
    );

    elements.forEach((element) => new Modal(element));
  }
}
