import IntroTeaser from './intro-teaser';

export default async function init() {
  const introTeaser = document.querySelector(
    `[data-js-module="o-intro-teaser"]`
  );
  if (introTeaser) {
    new IntroTeaser(introTeaser);
  }
}
