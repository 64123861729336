export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="m-pwa-teaser"]');
  if (elements.length) {
    const { default: PWATeaser } = await import(
      /* webpackChunkName: "m-pwa-teaser" */ './pwa-teaser'
    );

    elements.forEach((element) => new PWATeaser(element));
  }
}
